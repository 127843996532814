/**
 * App Constants
*/

export default {
    AppLogo: require('../assets/images/logo2.svg'),
   AppLogoWhite: require('../assets/images/logo.png'),         // App logo
    // App logo
   rtlLayout: false,                                             // RTL Layout
   adminLayout: false,                                           // Admin Layout
	navCollapsed: false,                                          // Sidebar Nav Layout
   algoliaConfig: {                                              // Algolia configuration
      appId: 'latency',
      apiKey: '3d9875e51fbd20c7754e65422f7ce5e1',
      indexName: 'bestbuy'
   },
   // Default locale
   locale: {
      locale: 'es',
      name: 'Spanish',
      icon: 'es',
   },
   // Footer about description
   AboutUs: 'Texto acerca de la empresa',
   // Copyright text
   CopyrightText: '© Todos los derechos reservados | Biosintex24hs.com  '
}